<template>
    <div id="alert-template" class="pt-5">
    <div v-if="$page.props.flash.error" class="mb-3">
        <v-alert
            type="error"
            variant="outlined"
            density="compact"
        >
            {{$page.props.flash.message}}
        </v-alert>
<!--        <div class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-3" role="alert">-->
<!--            <strong class="font-bold">{{$page.props.flash.error}} !</strong>-->
<!--            <span class="block sm:inline">&nbsp; {{$page.props.flash.message}}</span>-->
<!--        </div>-->
    </div>
    <div v-if="$page.props.flash.success">
        <v-alert
            type="success"
            variant="outlined"
            density="compact"
        >
            {{$page.props.flash.message}}
        </v-alert>
    </div>
    <div v-if="$page.props.flash.warning">
        <v-alert
            type="warning"
            variant="outlined"
            density="compact"
        >
            {{$page.props.flash.message}}
        </v-alert>
    </div>
    <div v-if="$page.props.errors.attempting_error" class="mb-3">
        <v-alert
            type="error"
            variant="outlined"
            density="compact"
        >
            {{$page.props.errors.attempting_error}}
        </v-alert>
    </div>
    <div v-if="$page.props.errors.authenticator" class="mb-3">
        <v-alert
            type="error"
            variant="outlined"
            density="compact"
        >
            {{$page.props.errors.authenticator}}
        </v-alert>
    </div>
    </div>
</template>